import React from "react";

import Layout from "../components/Layout";

const AboutPage = () => (
    <Layout>
        <div id="main">
            <div className="about_section_banner">
                <div className="about_banner">
                    <h1>About Audaco</h1>
                </div>
            </div>
            <div className="inner">
                <div className="about_section_1">
                    <h1 className="about_section_1__title">Learn more about us</h1>
                    <p className="about_section_1__paragraph">
                        We are a high-tech software company, based in the city of
                        queretaro, mexicoxas; with more than 20 years of experience in developing customized
                        erps and crms.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
);

export default AboutPage;
